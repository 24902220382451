import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/auth-context';
import { usePermissions } from '../hooks/use-permissions';
import { ARC_GROUP, BillingPermissionData } from '../types';
import { captureError } from '../utils/capture-error';
import { checkForRedirectPath, storeRedirectPath } from '../utils/redirect';
import { AgreementAlert } from './agreement-alert';
import { AuthorizeError } from './authorize-error';
import { MaintenanceMessageAgents } from './maintenance-message';

export function RequireAuth(props: {
  children: JSX.Element;
  isAdmin: boolean;
  maintData:
    | {
        maintenanceActive: boolean;
        maintenanceBegin: string;
        maintenanceEnd: string;
      }
    | null
    | undefined;
}) {
  // Context
  const { state, dispatch } = React.useContext(AuthContext);
  // State
  const [dismissICA, setDismissICA] = React.useState(false);
  // Hooks
  const navigate = useNavigate();
  const { hasGroup } = usePermissions();

  React.useEffect(() => {
    const handleCheckForPath = async () => {
      try {
        const redirectPath = await checkForRedirectPath();
        if (redirectPath) {
          navigate(redirectPath);
        }
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    const handleStorePath = async () => {
      try {
        if (window?.location?.pathname) {
          const { pathname } = window.location;
          // If the user was trying to access a route other than the main route
          if (pathname !== '/') {
            let path = pathname;
            // If there are any search parameters add them to the redirect path
            if (window?.location?.search) {
              path = path + window.location.search;
            }
            // Add the route to storage for use after logging in
            await storeRedirectPath({ pathname: path });
          }
        }
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    if (state.user !== undefined) {
      // Check for stored path
      handleCheckForPath();
    } else {
      // Store path
      handleStorePath();
    }
  }, [state.user, navigate, dispatch]);

  const isAuthenticated = state.user !== undefined;

  // // Query - Authorize User
  const pathAuth = '/authorize';
  const queryAuth = useQuery({
    enabled: isAuthenticated && process.env.REACT_APP_AWS_BRANCH === 'master',
    queryKey: [pathAuth],
    queryFn: async () => {
      const response: {
        data: { LastAccessTime: string };
      } = await API.post('DashboardAPI', pathAuth, {});

      return response.data;
    },
    onError: (error: any) => {
      if (error?.response?.status === 403) {
        if (error?.response?.data?.data?.UserStatus === 'Pending') {
          // The user has a status of Terminated
          dispatch({ type: 'AUTHORIZE_ERROR', payload: 'pending' });
        } else if (error?.response?.data?.data?.UserStatus === 'Terminated') {
          // The user has a status of Terminated
          dispatch({ type: 'AUTHORIZE_ERROR', payload: 'terminated' });
        } else if (error?.response?.data?.data?.UserStatus === 'Inactive') {
          // The user has a status of Inactive
          dispatch({ type: 'AUTHORIZE_ERROR', payload: 'inactive' });
        } else {
          // The user has a status of Pending or Unknown
          dispatch({ type: 'AUTHORIZE_ERROR', payload: 'unauthorized' });
        }
      } else {
        // The endpoint responded with an error, but it doesn't
        // mean the user is inactive or terminated
        dispatch({ type: 'AUTHORIZE_ERROR', payload: 'default' });

        // Only capture Errors that don't have a status of 403
        captureError({ data: { error } });
      }
    },
  });

  // // Query - Check for Signed ICA and verified NPN
  const pathStatus = '/authorize/status';
  const queryStatus = useQuery({
    enabled: isAuthenticated,
    queryKey: [pathStatus],
    queryFn: async () => {
      const response: {
        data: {
          HasSignedICA: boolean;
          NPNVerified: boolean;
        } | null;
      } = await API.post('DashboardAPI', pathStatus, {});

      if (response.data) {
        dispatch({ type: 'SET_STATUS', payload: response.data });
      }

      return response.data;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Billing Permissions
  const pathBilling = '/access/user-permissions';
  const queryBilling = useQuery({
    enabled: isAuthenticated && hasGroup(ARC_GROUP.ARC_BillingSystem),
    queryKey: [pathBilling],
    queryFn: async () => {
      const response: {
        data: BillingPermissionData[];
      } = await API.post('BillingAPI', pathBilling, {});

      if (response.data.length) {
        dispatch({ type: 'SET_BILLING_PERM', payload: response.data });
      } else {
        dispatch({ type: 'SET_BILLING_PERM', payload: [] });
      }

      return response.data;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Not all users will have Billing permissions
  // We need to check the fetch status to see if the query is actually running
  const isLoadingBilling =
    queryBilling.isLoading && queryBilling.fetchStatus !== 'idle';

  // We need to check the fetch status to see if the query is actually running
  const isLoadingICA =
    queryStatus.isLoading && queryStatus.fetchStatus !== 'idle';

  const isAuthorizing = queryAuth.isLoading && queryAuth.fetchStatus !== 'idle';

  if (!props.isAdmin && props.maintData && props.maintData.maintenanceActive) {
    // Display a Maintenance Message to agents
    return (
      <MaintenanceMessageAgents
        maintenanceEnd={props.maintData.maintenanceEnd}
      />
    );
  } else if (
    state.isChecking ||
    isAuthorizing ||
    isLoadingICA ||
    isLoadingBilling
  ) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  } else if (
    queryStatus.data &&
    queryStatus.data.HasSignedICA === false &&
    !dismissICA
  ) {
    return <AgreementAlert onDismiss={() => setDismissICA(true)} />;
  } else if (queryAuth.isError) {
    return <AuthorizeError />;
  } else if (isAuthenticated) {
    // It is possible for a user to be given the Cognito Billing Permission
    // but they have not yet been added as a User in the Billing System
    const alertBillingPermissions =
      queryBilling.error &&
      // @ts-ignore
      queryBilling.error?.response?.request?.status === 403;

    return (
      <React.Fragment>
        {alertBillingPermissions ? (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              zIndex: 3,
            }}
          >
            <Container maxWidth="sm" sx={{ p: 1 }}>
              <Alert severity="error" variant="filled">
                A Billing Admin needs to add you in Billing User Administration.
              </Alert>
            </Container>
          </Box>
        ) : null}

        {props.children}
      </React.Fragment>
    );
  } else {
    return <Navigate to="/sign-in" replace />;
  }
}
